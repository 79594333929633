import React, { useEffect, useState } from "react";
import MusicHeader from "../../components/MusicHeader";
import styles from './Marketplace.module.scss'
import { Button, Divider, Input, List, message } from "antd";
import { ReactComponent as Search } from '../../assets/musicbet/video_search.svg'
import { ReactComponent as Settings } from '../../assets/musicbet/tune.svg'
import { ReactComponent as Arrow } from '../../assets/musicbet/arrow_drop_down.svg'


import peggy from '../../assets/mock/Peggy-Gou-LP-web-e1712588331306.jpg'
import illit from '../../assets/mock/battle.jpg'
import MarketplaceSong from "../../components/MarketplaceSong";
import { CloseOutlined } from "@ant-design/icons";
import { ReactComponent as Sort } from '../../assets/musicbet/swap_vert.svg'
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { genresAPI, marketplaceAPI, profileAPI, serviceApi, tracksAPI } from "../../api/api";
import { NotificationT } from "../../components/ToastifyNot/NotificationToastify";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUserData } from "../../store/playerSlice";
import { useMediaQuery } from "react-responsive";
import ShowMore from "../../components/ShowMore";

const MarketPlace = () => {
  const [data, setData] = useState<any>([])
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Количество элементов на странице
  const [searchParams] = useSearchParams();
  const [isFiltersShown, setFiltersShown] = useState(false)
  const [isGenreShown, setGenreShown] = useState(false)
  const [isYearShown, setYearShown] = useState(false)
  const [isSortShown, setSortShown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataById, setDataById] = useState<any>()
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState(''); // добавлен стейт для поиска
  const [inputAmount, setInputAmount] = useState<any>()
  const [query, setQuery] = useState<any>()
  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });
  let songId: any = searchParams.get('id');
  const [filters, setFilters] = useState({ year: null, genre: { id: null, name: null }, sort: { order: null } });
  const [years, setYears] = useState<any>()
  const [mobile, setMobile] = useState<any>()
  const [desktop, setDesktop] = useState<any>()
  const dispatch = useDispatch()

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    serviceApi
      .getConfig()
      .then((res) => {
        setDesktop(res["shop.banner.desktop"])
        setMobile(res["shop.banner.mobile"])
      })
  })
  useEffect(() => {
    const fetchMarketplaceData = () => {
      const query = [];

      // Добавляем фильтр по году, если он установлен
      if (filters.year !== null) {
        query.push(`year=${filters.year}`);
      }

      // Добавляем фильтр по жанру, если он установлен
      if (filters.genre && filters.genre.id !== null) {
        query.push(`category_id=${filters.genre.id}`);
      }

      // Добавляем сортировку по цене, если она установлена
      if (filters.sort.order) {
        query.push(`price_order=${filters.sort.order}`); // Например, price_order=asc или price_order=desc
      }

      if (searchValue) {
        query.push(`search=${searchValue}`);
      }

      // Формируем строку запроса, только если есть добавленные фильтры
      const queryString = query.length > 0 ? `&${query.join('&')}` : '';
      setQuery(queryString);

      // Запрос к API для получения данных
      marketplaceAPI.getMarketplace(pageSize, currentPage, queryString)
        .then((res) => {
          setData(res);
        })
        .catch((error) => {
          console.error("Error fetching marketplace data: ", error);
          toast.error('Error fetching marketplace data');
        });

      // Получение лет
      marketplaceAPI.getYears()
        .then((res) => {
          setYears(res);
        })
        .catch((error) => {
          console.error("Error fetching years: ", error);
          toast.error('Error fetching years');
        });

      // Получение жанров
      genresAPI.getGenres()
        .then((res) => {
          setGenre(res);
        })
        .catch((error) => {
          console.error("Error fetching genres: ", error);
          toast.error('Error fetching genres');
        })
        .finally(() => {
          setIsLoading(false); // Скрываем загрузку после завершения всех запросов
        });
    };
    // setCurrentPage(1)
    fetchMarketplaceData(); // Вызов функции для первоначальной загрузки данных
  }, [filters, searchValue, currentPage]); // Эффект будет срабатывать при каждом изменении в filters


  const buyTrack = () => {
    marketplaceAPI
      .buyTrack(dataById.id, inputAmount)
      .then((res) => {
        toast.success(`Congratulations! You’ve successfully got the card and it has been added to your collection!`)
      })
      .then((res) => navigate('/store'))
      .then(() => {
        profileAPI
          .getProfile()
          .then((res) => {
            dispatch(
              setUserData({
                first_name: res?.first_name,
                last_name: res?.last_name,
                nickname: res?.nickname,
                email: res.email,
                gender: res.gender,
                phone: res.phone,
                id: res.id,
                balance: res.balance,
                avatar: res?.avatar,
                first_voted: res?.data?.first_voted,
                income: res?.transaction_sums?.income,
                expense: res?.transaction_sums?.expense,
                transactions: res?.videobet_transactions,
                stocks: res?.stocks,
                account_id: res.account_id
              })
            )
          });
      })
      .then(() => {
        marketplaceAPI.getMarketplace(pageSize, currentPage, query)
          .then((res) => {
            setData(res);
          })

          .catch((error) => {
            console.error("Error fetching marketplace data: ", error);
          });
      })
      .catch(() => {
        toast.error("You don't have enough VSTokens. Please add more tokens to your account")
      })
  }

  const showGenre = () => {
    if (isGenreShown === true) {
      setGenreShown(false)
    } else {
      setYearShown(false)
      setGenreShown(true)
    }
  }

  const showYear = () => {
    if (isYearShown === true) {
      setYearShown(false)

    } else {
      setYearShown(true)
      setGenreShown(false)
    }
  }

  const showFilters = () => {
    if (isFiltersShown === true) {
      setFiltersShown(false)
    } else {
      setFiltersShown(true)
      setYearShown(false)
      setSortShown(false)
    }
  }

  const showSort = () => {
    if (isSortShown === true) {
      setSortShown(false)
    } else {
      setSortShown(true)
      setFiltersShown(false)
      setGenreShown(false)
      setYearShown(false)
    }
  }



  const setYearFilter = (year) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      year: year
    }));
    setFiltersShown(false); // Закрыть панель фильтров
    setCurrentPage(1)
    setYearShown(false); // Закрыть список годов
  };

  const setGenreFilter = (genreId, genreName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      genre: { id: genreId, name: genreName }
    }));
    setFiltersShown(false); // Закрыть панель фильтров
    setCurrentPage(1)
    setGenreShown(false); // Закрыть список жанров
  };

  const setSortFilter = (order) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      sort: { order: order } // Устанавливаем порядок сортировки
    }));
    setFiltersShown(false); // Закрыть панель фильтров
    setCurrentPage(1)
    setSortShown(false); // Закрыть список сортировок
  };



  const [genre, setGenre] = useState<any>();
  const genres = genre?.map(g => ({
    value: g.id,
    label: g.name
  }));


  if (isLoading) {
    return null
  }
  const handleSearch = (e) => {
    setSearchValue(e.target.value); // Обновление стейта при вводе в поле поиска
  };



  if (!songId) {
    // if (isLoading) {
    //   return null
    // }
    return (
      <>
        {!isDesktop && <MusicHeader title="V4S Store" primaryColor="#F12027" color="#fff" />}



        <div className={styles.wrapper}>
        <ShowMore>
          <p className={styles.info} style={{margin: 5}}>
            <strong><span>🚨</span> Multiply rewards 2X, 3X, 5X with Perpetual Cards ! <span>🚀</span></strong> <br />
            ✅ This lifetime asset is yours to auction with X or gift to others users
            <span> 🆙📈🎁</span> <br />
            <span>✅</span> Guaranteed rewards for the owner: 10% of the Battle Pot if the track wins, and 5% even if it loses <span>🏵🧮</span> <br />
            <span>✅</span> Exclusivity as the only cardholder on the V4S platform🃏 <br />
            The number of games in which your card can be placed will constantly increase <span>💯</span> <br />
            <span>✅</span> Cardholders can increase their rewards by voting for their own tracks <span>🗳💸</span><br />
          </p>
        </ShowMore>
          <div className={styles.banner_wrapper}>
            <img className={styles.banner} src={isDesktop ? desktop : mobile} alt="banner" />
          </div>
          <div className={styles.filters}>
            <Input
              placeholder="Search"
              value={searchValue} // привязываем к стейту поиска
              onChange={handleSearch} // обрабатываем ввод
              suffix={
                <Button className={styles.button_search} type="link">
                  <Search />
                </Button>
              }
            />
            <div className={styles.settings}>
              <Button onClick={() => { showFilters() }} className={styles.filters_btn}>
                <Settings />
                <p>Filters</p>
              </Button>
              <Button onClick={() => { showSort() }} className={styles.filters_btn}>
                <Sort />
                <p>Sort</p>
              </Button>

            </div>

            <div className={styles.filters_all} style={isFiltersShown ? { display: "flex" } : { display: 'none' }}>
              <div className={styles.filters_column}>

                <Button onClick={() => showGenre()} className={styles.filters_btn}>
                  <Arrow />
                  <p>Genre</p>
                </Button>
                <Button onClick={() => showYear()} className={styles.filters_btn}>
                  <Arrow />
                  <p>Year</p>
                </Button>
              </div>

              <div translate="no" className={styles.all_genres} style={isGenreShown ? { display: "grid" } : { display: 'none' }}>
                {genres?.map((item) => (
                  <Button
                    className={styles.filter_button}
                    onClick={() => { setFiltersShown(false); setGenreFilter(item.value, item.label); }}
                  >
                    {item.label}
                  </Button>
                ))}
              </div>
              <div className={styles.all_genres} style={isYearShown ? { display: "grid" } : { display: 'none' }}>
                {years?.data?.map((item) => {
                  return (
                    <Button className={styles.filter_button} onClick={() => { setFiltersShown(false); setYearFilter(item) }}>{item}</Button>
                  )
                })}
              </div>
              <div className={styles.all_genres} style={isSortShown ? { display: "grid" } : { display: 'none' }}>
                {years?.data?.map((item) => {
                  return (
                    <Button className={styles.filter_button} onClick={() => { setFiltersShown(false); setYearFilter(item) }}>{item}</Button>
                  )
                })}
              </div>
            </div>

            <div style={isSortShown ? { display: "flex" } : { display: "none" }} className={styles.sort_by}>
              <Button className={styles.button} onClick={() => setSortFilter('asc')}>
                Value: Low to High
              </Button>
              <Button className={styles.button} onClick={() => setSortFilter('desc')}>
                Value: High to Low
              </Button>
            </div>
            <Divider style={filters.genre.id != null || filters.year != null || filters.sort.order != null ? { background: "#fff" } : { display: 'none' }} />

            <div className={styles.filter_buttons}>
              <div translate="no">
                {filters.genre.id != null && (
                  <Button className={styles.all_filters_btns} onClick={() => setGenreFilter(null, null)}>
                    {filters.genre.name} <CloseOutlined />
                  </Button>
                )}
                {filters.year != null && (
                  <Button className={styles.all_filters_btns} onClick={() => setYearFilter(null)}>
                    {filters.year} <CloseOutlined />
                  </Button>
                )}
                {filters.sort.order && (
                  <Button className={styles.all_filters_btns} onClick={() => setSortFilter(null)}>
                    {filters.sort.order === 'asc' ? 'Price: Low to High' : 'Price: High to Low'} <CloseOutlined />
                  </Button>
                )}
              </div>
              {(filters.year != null || filters.genre.id != null || filters.sort.order != null) && (
                <Button
                  onClick={() => {
                    setGenreFilter(null, null);
                    setYearFilter(null);
                    setSortFilter(null);
                  }}
                  className={styles.all_filters_btns}
                >
                  Clear
                </Button>
              )}
            </div>

            <div className={styles.songs}>

              <List
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  total: data?.meta?.total, // Общее количество элементов
                  onChange: handlePageChange,
                  position: "bottom",
                  align: "center",
                }}


                dataSource={data?.data}
                renderItem={(item: any, index) => (
                  <>
                    {/* <Button type="link"> */}
                    <div style={{ cursor: 'pointer' }} onClick={() => { setDataById(item); navigate(`?id=${item.id}`) }}>

                      <MarketplaceSong
                        id={item?.id}
                        genre={item?.categories_names}
                        cover={item?.cover}
                        artist={item.artists_names}
                        track={item.name}
                        year={item.year}
                        price={item.price}
                      />

                      <Button className={styles.buy} onClick={() => { setDataById(item); navigate(`?id=${item.id}`) }}>
                        Get
                      </Button>
                      {/* </Button> */}
                    </div>
                  </>
                )}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
  else {

    return (
      <div className={styles.checkout_wrapp}>
        {/* <NotificationT /> */}
        {!isDesktop && <MusicHeader title="checkout" color="#fff" primaryColor="#F12027" returnable />}

        <img className={styles.checkout_cover} src={dataById?.cover} alt="cover" />
        <div className={styles.checkout_info}>
          <p>Genre: <span translate="no"> {dataById?.categories_names.map((genre, index) =>
            (index === dataById?.categories_names.length - 1 ? genre.name : `${genre.name}, `)
          )}</span></p>
          <p>Artist: <span translate="no">
            {dataById?.artists_names.map((artist, index) =>
            (index === dataById?.artists_names.length - 1 ? artist.name : `${artist.name}, `)

          )}</span></p>
          <p>Track: {dataById?.name}</p>
          <p>Year: {dataById?.year}</p>
        </div>
        <div>
          <div className={styles.checkout_actions_header}>
            <h2>get this track</h2>
          </div>
          <div className={styles.checkout_actions_price}>
            <p> {dataById?.price} VST</p>
            {/* <Input max={dataById?.price} step={1} required placeholder="1+ TKN" type="number" value={dataById?.price} /> */}
          </div>
        </div>
        {/* <Button onClick={() => { buyTrack() }} disabled={inputAmount !== undefined ? false : true} className={styles.checkout_actions_buy}>Buy</Button> */}
        <Button onClick={() => { buyTrack() }} className={styles.checkout_actions_buy}>Get</Button>
        <p className={styles.under_get}>Cards can be gifted to any user at any time, listed for auction at any time and can be sold back to the platform one year after the purchase date. Please refer to the 'How To Play' section on the website to review all terms and conditions.</p>
      </div>
    )
  }
}

export default MarketPlace;
