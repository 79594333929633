import React from "react";
import styles from './GetTokens.module.scss'
import { Button } from "antd";
import { ReactComponent as BuyTokensIcon } from '../../assets/musicbet/account/poker_chip.svg'
import { useNavigate } from "react-router-dom";

const GetTokens = () => {

 const navigate = useNavigate()

 return (
  <div className={styles.get_tokens}>
   <p> <span>Get VSTokens to START</span> 🏃‍➡️➡️</p>
   <Button onClick={() => {navigate('/profile/buy-vstokens')}}><BuyTokensIcon /> Buy Tokens</Button>
  </div>
 )
}

export default GetTokens