import React from "react";
import styles from './BattleResultsItem.module.scss'
import { sprintf } from 'sprintf-js'


interface BattleProps {
 index: number,
 genre: any,
 ended_at: string,
 started_at?: string,
 winner: string,
 prize: number,
 players: number,
 cover: string,
 static_cover: string,
 battle: number,
 artist: any
 loser?: any,
}

const BattleResultsItem = (props: BattleProps) => {

 return(
  <div className={props.index % 2 === 0 ? styles.battleItem : styles.battleItem_even}>
   <div className={styles.cover_wrapp}>
  <img className={styles.cover} src={props?.static_cover || props?.cover} alt="cover"/>
  <p className={props.index % 2 === 0 ? styles.all_artists : styles.all_artists_even}>{props.artist} VS <br /> {props.loser}</p>
   </div>
   <div className={styles.text_wrapper}>
    <p className={props.index % 2 === 0 ? styles.description : styles.description_even}>Tournament: <span translate="no">{props.genre}</span></p>
    <p className={props.index % 2 === 0 ? styles.description : styles.description_even}>Battle: {props.battle}</p>
    <p className={props.index % 2 === 0 ? styles.description : styles.description_even}>Start Date, Time: {props.started_at}</p>
    <p className={props.index % 2 === 0 ? styles.description : styles.description_even}>End Date, Time: {props.ended_at}</p>
    <p className={props.index % 2 === 0 ? styles.description : styles.description_even}>Winner: <span translate="no">{props.artist}</span></p>
    <p className={props.index % 2 === 0 ? styles.description : styles.description_even}>Loser: <span translate="no">{props.loser}</span></p>
    <p className={props.index % 2 === 0 ? styles.description : styles.description_even}>Battle pot: {sprintf('%.2f',props.prize)} VST</p>
    <p className={props.index % 2 === 0 ? styles.description : styles.description_even}>Winning players: {props.players}</p>
   </div>
  </div>
 )
}

export default BattleResultsItem;
