import React, { useEffect, useState } from "react";
import styles from './BattleItem.module.scss'
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Input, Popover } from "antd";
import { useMediaQuery } from "react-responsive";
import { setUserData } from "../../store/playerSlice";
import { VimeoPlayer } from 'reactjs-vimeo-player'
import { ReactComponent as TitleDesktop } from '../../assets/musicbet/cards/desktop-menu.svg'
import { ReactComponent as SubtitleDesktop } from '../../assets/musicbet/cards/desktop-menu-sub.svg'
import { useDispatch } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import { battleAPI, profileAPI, tracksAPI } from "../../api/api";
import { Fade } from "react-awesome-reveal";
import { Slide } from "react-awesome-reveal";

import { toast } from "react-toastify";
import momentTimeZone from "../../hooks/momentTimeZone";

interface ItemProps {
  battle?: string,
  image?: any,
  primaryColor?: string,
  secondaryColor?: string
  id?: any
  video?: any
  artist?: any
  song?: string
  end_date?: string
  video_240_url?: string,
  video_360_url?: string,
  video_480_url?: string,
  video_720_url?: string,
  video_1080_url?: string,
  display_id?: number,
  track_id?: number,
  track?: any,
  onChange?: any
  idk?: any
  data?: any
  static?: string
}

const MusicBattleItem = (props: ItemProps) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [data, setData] = useState<any>()
  const [isPopoverOpen, setPopoverOpen] = useState(false)


  const getWinnerName = () => {
    const winnerTrackId = props?.data?.data?.winner_track_id;
    let winnerName = '';

    if (props?.data?.track1?.id === winnerTrackId) {
      winnerName = props?.data?.track1?.artists_names?.map(artist => artist.name).join(' & ');
    } else if (props?.data?.track2?.id === winnerTrackId) {
      winnerName = props?.data?.track2?.artists_names?.map(artist => artist.name).join(' & ');
    }

    return winnerName || 'Unknown winner';
  }

  useEffect(() => {
    if (props.id && localStorage.getItem('isAuth')) {
      battleAPI.getBattleById(props.id)
        .then(data => {
          setData(data)
        })
    } else if (props.id) {
      battleAPI.getBattleByIdAnonim(props.id)
        .then(data => {
          setData(data)
        })
    }
  }, [props?.id])

  const location = useLocation();

  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });

  const currentDate = new Date()

  const handleChange = () => {
    props.onChange();
  };
  const endDate = new Date(props.end_date);

  const formattedDate = !isNaN((endDate.getTime())) ? momentTimeZone(endDate, "YYYY-MM-DD") : "Coming soon";

  const onFinish = (values) => {
    const amount = parseInt(values.amount); // Получаем значение из формы
    tracksAPI
      .bidTrack(props.id, props.track_id, amount)
      .then(() => toast.success(`Congrats! Your ${amount} VST vote for ${props.artist[0].name} has been successfully cast`))
      .then(() => {
        battleAPI.getBattleById(props.id)
          .then(data => {
            setData(data)
          })
          .then(() => {
            handleChange()
          })
      })
      .then(() => {
        profileAPI
          .getProfile()
          .then((res) => {
            dispatch(
              setUserData({
                first_name: res?.first_name,
                last_name: res?.last_name,
                nickname: res?.nickname,
                email: res.email,
                gender: res.gender,
                phone: res.phone,
                id: res.id,
                balance: res.balance,
                avatar: res?.avatar,
                first_voted: res?.data?.first_voted,
                income: res?.transaction_sums?.income,
                expense: res?.transaction_sums?.expense,
                transactions: res?.videobet_transactions,
                stocks: res?.stocks,
                account_id: res?.account_id
              })
            )
          });
      })
      .then(() => {
      })
      .catch(() => {
        if (endDate < currentDate) {
            toast.error('The battle is over. Please choose another one.')
        } else if (props?.data?.state === "created") {
          if (props?.data?.current_tournament_stage < props?.data?.tournament_stage) {
            toast.error('The votes and tokens are being processed and will be distributed soon. The next round will begin shortly.')
          }
          else {
          toast.error('Please wait for the battle to begin.')
        }
      }
        else {
          toast.error('Log in or sign up to your account and get VSTokens to cast your vote!')
        }
      })
    form.resetFields()
    setPopoverOpen(false)
  };

  const popoverContent = (
    <div className={styles.popover_amount}>
      <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
        <p>Place Your Amount</p>
        <Button onClick={() => setPopoverOpen(false)} style={{ background: 'none', aspectRatio: 1, marginTop: 0, paddingTop: 0, paddingRight: 0 }} type='link'>
          <CloseOutlined />
        </Button>
      </div>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          style={{ marginBottom: 10, paddingBottom: 10 }}
          name="amount"
          rules={[{ required: true, message: 'Please input your amount!' }]}
        >
          <Input
            min={1}
            type="number"
            step={1}
            defaultValue={null}
          />
        </Form.Item>
        <Form.Item style={{ height: 'min-content', width: 'min-content', margin: '0 auto' }}>
          <Button className={styles.button_confirm} style={{ margin: '0 auto' }} type="primary" htmlType="submit">CONFIRM</Button>
        </Form.Item>
      </Form>
    </div>
  )

  const navigate = useNavigate()


  function mapArtists(artist) {
    if (artist?.length > 1) {
      return artist?.name.join(', ');
    }
    return artist?.length === 1 ? artist[0].name : null;
  }
  return (
    <>
      <div onClick={localStorage.getItem('isAuth') ? location.pathname !== "/tournament/" ? () => { } : props.id === undefined ? () => { } : () => navigate(`/battle/?id=${props.id}&display=${props.display_id}`) : () => { }} className={styles.wrapper}>
        <div style={{ position: "relative", zIndex: 11 }} id="image_container" className={styles.image_container}>
          {
            location.pathname !== "/tournament/" ?
              <VimeoPlayer
                fullWidth={true}
                id={props?.video_1080_url}
              />
              :
              <div>
                {
                  data?.state === "completed" && <p className={styles.winner_info}>Winner: <span translate="no">{getWinnerName()}</span></p>
                }
                {
                  data?.state === "completed" ?
                    <img style={(data?.state === "completed" && location.pathname === "/tournament/") ? { filter: 'grayscale(100%)' } : {}} className={styles.cover} src={props.data.data.static === undefined ? props.image : props.data.data.static} alt="battle" />
                    :
                    <img style={(data?.state === "completed" && location.pathname === "/tournament/") ? { filter: 'grayscale(100%)' } : {}} className={styles.cover} src={props.image === undefined ? props.idk : props.image} alt="battle" />

                }
              </div>
          }
        </div>
        {
          !isDesktop && location.pathname !== '/tournament/' ?
            <div>
              <Slide triggerOnce style={{ zIndex: 10, position: "relative" }} delay={1000} direction="down">
                <div style={{ zIndex: 10 }} className={styles.big_under}>
                  <TitleDesktop style={{ fill: props.primaryColor, zIndex: 10 }} className={styles.titleDesktop} />
                  {
                    location.pathname !== '/tournament/' ?
                      <p translate="no" className={styles.tournaments_title} style={{ display: 'block', zIndex: 100 }}>{mapArtists(props.artist)} - {props.song}</p>
                      :
                      <h2 className={styles.tournaments_title} style={{ display: 'block', zIndex: 100 }}>BATTLE {props?.display_id}</h2>

                  }
                </div>
              </Slide>
              <Slide triggerOnce style={{ zIndex: 9, position: "relative" }} delay={1500} direction="down">
                <Fade triggerOnce delay={1600}>
                  <div className={styles.small_under}>
                    <SubtitleDesktop style={{ fill: props.secondaryColor, zIndex: 10 }} className={styles.subtitleDesltop} />
                    {
                      location.pathname !== "/tournament/" ?
                        <div className={styles.counter}>
                          <Popover open={isPopoverOpen} trigger={'click'} content={popoverContent}>
                            <div className={styles.white_place}>
                              <p onClick={() => setPopoverOpen(true)} className={styles.counter_amount}>Vote Amount</p>
                            </div>
                          </Popover>
                        </div>
                        :
                        <h2>{data?.state === 'completed' ? "Finished" : formattedDate}</h2>
                    }
                  </div>
                </Fade>
              </Slide>
            </div>
            :
            location.pathname === '/tournament/' && !isDesktop
              ?
              <div>
                <div style={{ zIndex: 10, height: 20 }} className={styles.big_under}>
                  <TitleDesktop style={{ fill: props.primaryColor, zIndex: 10 }} className={styles.titleDesktop} />
                  {
                    location.pathname !== '/tournament/' ?
                      <p translate='no' className={styles.tournaments_title} style={{ display: 'block', zIndex: 100 }}>{mapArtists(props.artist)} - {props.song}</p>
                      :
                      <h2 className={styles.tournaments_title} style={{ display: 'block', zIndex: 100 }}>BATTLE {props?.display_id}</h2>
                  }
                </div>

                <div style={{ height: 20, margin: "0 20%" }} className={styles.small_under}>
                  <SubtitleDesktop style={{ fill: props.secondaryColor, zIndex: 10 }} className={styles.subtitleDesltop} />
                  {
                    location.pathname !== "/tournament/" ?
                      <div className={styles.counter}>
                        <Popover open={isPopoverOpen} trigger={'click'} content={popoverContent}>
                          <p onClick={() => setPopoverOpen(true)} className={styles.counter_amount}>Vote Amount</p>
                        </Popover>
                      </div>
                      :
                      <h2 style={{ zIndex: 11, fontSize: '12px !important' }}>{data?.state === 'completed' ? "Finished" : formattedDate}</h2>
                  }
                </div>
              </div>
              :
              <div>
                <div className={styles.big_under}>
                  <TitleDesktop style={{ fill: props.primaryColor, zIndex: 10 }} className={styles.titleDesktop} />
                  {
                    location.pathname !== '/tournament/' ?
                      <p translate="no" className={styles.tournaments_title} style={{ display: 'block', zIndex: 100 }}>{mapArtists(props.artist)} - {props.song}</p>
                      :
                      <h2 style={{ display: 'block', zIndex: 100 }}>BATTLE {props?.display_id}</h2>
                  }
                </div>

                <div className={styles.small_under}>
                  <SubtitleDesktop style={{ fill: props.secondaryColor, zIndex: 100 }} className={styles.subtitleDesltop} />
                  {
                    location.pathname !== "/tournament/" ?
                      <div style={{ zIndex: 100 }} className={styles.counter}>
                        <div className={styles.white_place}>
                          <Popover open={isPopoverOpen} trigger={'click'} content={popoverContent}>
                            <p onClick={() => { setPopoverOpen(true) }} className={styles.counter_amount}>Vote Amount</p>
                          </Popover>
                        </div>
                      </div>
                      :
                      <h2 style={{ display: 'block', zIndex: 100 }}>{data?.state === 'completed' ? "Finished" : formattedDate}</h2>
                  }
                </div>
              </div>
        }
      </div>
    </>
  )
}

export default MusicBattleItem;
