import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import styles from './ShowMore.module.scss'
import { Button } from "antd";

const ShowMore = ({ children }) => {
 const [isExpanded, setIsExpanded] = useState(false);

 return (
  <div className={styles.wrapper}>
   <motion.div
    initial={{ height: "calc(3em + 5px)"  }} // Высота двух строк текста
    animate={{ height: isExpanded ? "auto" : "calc(3em + 5px)" }}
    style={{
     overflow: "hidden",
     lineHeight: "1.5em", // Линия текста для расчета 2 строк
    }}
   >
    {children}
   </motion.div>
   <Button
    onClick={() => setIsExpanded(!isExpanded)}
    className={styles.moreless}
   >
    {isExpanded ? "Show Less" : "Read More"}
   </Button>
  </div>


 )
}

export default ShowMore