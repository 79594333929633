import React, { useEffect, useState } from "react";
import styles from './MyTransaction.module.scss'
import { ProfileLayout } from "../../../components/ProfileLayout/ProfileLayout";
import { profileAPI } from "../../../api/api";
import { Table } from "antd";
import { sprintf } from 'sprintf-js'
import { useAppSelector } from "../../../hooks/reduxHooks";
import momentTimeZone from "../../../hooks/momentTimeZone";
import { Centrifuge } from 'centrifuge';


const MyTransaction = () => {
  const { account_id } = useAppSelector((state) => state.player);
  const [data, setData] = useState<any>()

  useEffect(() => {
    profileAPI
      .getTransactions()
      .then((res) => {
        setData(res.data)
      })
  }, [])

  const getName = (track, data) => {
    if (track === data?.battle?.track1?.id) {
      return data?.battle?.track1?.artists_names[0]?.name
    } else {
      return data?.battle?.track2?.artists_names[0]?.name
    }
  }

  const normalizeKind = (kind, data) => {
    switch (kind) {
      case ("bid_battle"):
        return (
          <p style={{ fontSize: 14 }}>Vote Casted <br />
            Genre: <span translate="no">
              {data?.battle?.category?.name}
              </span>
               <br />Round {data?.battle?.tournament_stage} <br />
            Artist:
            <span translate="no">
             {getName(data?.track_id, data)}
            </span>
            <br />
            ID: {data?.id}
          </p>
        )
      case ('auction_register_return'):
        return (
          <p style={{ fontSize: 14 }}>Auction Deposit Returned<br />
           <span translate="no"> {data?.auction?.track?.artists_names[0]?.name} - {data?.auction?.track?.name} </span>
            <br />
            ID: {data?.id}
          </p>
        )
      case ('auction_register'):
        return (
          <p style={{ fontSize: 14 }}>Auction Deposit<br />
            <span translate="no">{data?.auction?.track?.artists_names[0]?.name} - {data?.auction?.track?.name}</span>
            <br />
            ID: {data?.id}
          </p>
        )
      case ('auction_pay'):
        return (
          <p style={{ fontSize: 14 }}>Auction Card Bought<br />
           <span translate="no"> {data?.auction?.track?.artists_names[0]?.name} - {data?.auction?.track?.name}</span>
            <br />
            ID: {data?.id}
          </p>
        )
      case ('auction_sell'):
        return (
          <p style={{ fontSize: 14 }}>Auction Card Sold<br />
           <span translate="no"> {data?.auction?.track?.artists_names[0]?.name} - {data?.auction?.track?.name}</span>
            <br />
            ID: {data?.id}
          </p>
        )
      case ('sell_track'):
        return (
          <p style={{ fontSize: 14 }}>Sell track
            <br />
            ID: {data?.id}
          </p>
        )
      case ('buy_track'):
        return (
          <p style={{ fontSize: 14 }}>V4S Store Card Bought<br />
            {/* {data?.auction?.track?.artists_names[0]?.name} - {data?.auction?.track?.name} */}
            ID: {data?.id}
          </p>
        )
      case ('prize_battle_winner'):
        return (
          <p style={{ fontSize: 14 }}>Vote Won<br />
            Genre: <span translate="no">{data?.battle?.category?.name} </span> <br />Round {data?.battle?.tournament_stage} <br />
            Artist: <span translate="no">{getName(data?.track_id, data)}</span>
            <br />
            ID: {data?.id}
          </p>
        )
      case ('prize_battle_owner_lose'):
        return (
          <p style={{ fontSize: 14 }}>Cardholder gain<br />
            Genre: <span translate="no">{data?.battle?.category?.name} </span> <br />Round {data?.battle?.tournament_stage} <br />
            Artist: <span translate="no">{getName(data?.track_id, data)}</span>
            <br />
            ID: {data?.id}
          </p>
        )
      case ('prize_battle_owner_win'):
        return (
          <p style={{ fontSize: 14 }}>Cardholder gain<br />
             Genre: <span translate="no">{data?.battle?.category?.name} </span> <br />Round {data?.battle?.tournament_stage} <br />
             Artist: <span translate="no">{getName(data?.track_id, data)}</span>
            <br />
            ID: {data?.id}
          </p>
        )
      case ('prize_battle_grand_win'):
        return (
          <p style={{ fontSize: 14 }}>Tournament Bonus<br />
           Genre: <span translate="no">{data?.battle?.category?.name} </span> <br />Round {data?.battle?.tournament_stage} <br />
           Artist: <span translate="no">{getName(data?.track_id, data)}</span>
            <br />
            ID: {data?.id}
          </p>
        )
      case ('bid_battle_return'):
        return (
          <p style={{ fontSize: 14 }}>Vote Returned<br />
            Genre: <span translate="no">{data?.battle?.category?.name} </span> <br />Round {data?.battle?.tournament_stage} <br />
            Artist: <span translate="no">{getName(data?.track_id, data)}</span>
            <br />
            ID: {data?.id}
          </p>)
      case ('prize_battle_grand_collect'):
        return (
          <p style={{ fontSize: 14 }}>Tournament Bonus<br />
             Genre: <span translate="no">{data?.battle?.category?.name} </span> <br />Round {data?.battle?.tournament_stage} <br />
             Artist: <span translate="no">{getName(data?.track_id, data)}</span>
            <br />
            ID: {data?.id}
          </p>)
      case ('replenish'):
        return (
          <p style={{ fontSize: 14 }}>Deposited<br />
            ID: {data?.id}
          </p>)
      case ('voucher_use'):
        return (
          <p style={{ fontSize: 14 }}>Bonus VSTokens
            <br />
            ID: {data?.id}
          </p>)
      case ('withdraw'):
        return (
          <p style={{ fontSize: 14 }}>Collected VSTokens
            <br />
            ID: {data?.id}
          </p>)
    }

  }

  const columns = [
    {
      title: 'Transactions',
      dataIndex: 'kind',
      key: 'kind',
      width: '50%',
      render: (text, record) => (
        <span style={{ fontSize: 14 }}>{normalizeKind(text, record)} <br /></span>

      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: '50%',
      render: (text, record) => (
        <span style={{ fontSize: 14 }}>{account_id === record?.to_account_id ? `+${sprintf('%.2f', text)}` : `-${sprintf('%.2f', text)}`} VST <br />
          {momentTimeZone(record.updated_at, 'MMM D, YYYY, HH:mm')}
        </span>

      ),
    },
  ];

  return (
    <ProfileLayout>
      <Table className={styles.table} dataSource={data} columns={columns} />
    </ProfileLayout>
  )
}

export default MyTransaction;
