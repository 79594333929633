import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Col, Row, Table } from 'antd';
import { useMediaQuery } from 'react-responsive';
import ShowMoreText from "react-show-more-text";

import Timer from '../../Timer';

import s from '../BattleList.module.scss';
import Reveal, { Fade, Slide } from 'react-awesome-reveal';

import { keyframes } from "@emotion/react";
import MusicBattleItem from '../../BattleItem';
import Comments from '../../VideoPlayer/Comments';
import { battleAPI } from '../../../api/api';
import MusicHeader from '../../MusicHeader';
import { toast } from 'react-toastify';
import gif from '../../../assets/musicbet/icons/ezgif-2-7984cf3edc.gif'
import styled from 'styled-components';
import momentTimeZone from '../../../hooks/momentTimeZone';
import GetTokens from '../../GetTokens';
import ShowMore from '../../ShowMore';

const customAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const customAnimationHeight = keyframes`
from {
height: 120px;
}
to {
height: 70px
}
}
`


const BattleItem = () => {
    const [searchParams] = useSearchParams();
    const [battle, setBattle] = useState<any>()
    let battleId: any = searchParams.get('id');
    let display: any = searchParams.get('display')

    const StyledTable = styled(Table)`
    width: 100% !important;
  th {
    // background: #C983FF !important;
    border: none !important;
    // color: #9507FF !important;
    font-size: 14px;

    .ant-table-cell::before {
      display: none;
    }
  }

  thead {
    tbody {
      background-color: #f0f0f0 !important;
    }
    tr {
      th {
        padding-left: 0 !important;
        background-color: ${battle?.category?.data?.primary_color} !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        color: #fff !important;
        font-size: 12px !important
      };
        th:nth-child(2) {
        padding: 0 !important;
        }

    }



  }

  tbody {
    tr:nth-child(odd) {
      background-color: #f0f0f0 !important; // Цвет для нечётных строк
    }

    tr:nth-child(even) {
      background-color: #ffffff !important; // Цвет для чётных строк
    }

    tr {
      background-color: #000 !important;
              td {
    font-size: 12px !important
    }

      td:nth-child(1) {
        width: 15px !important;
        font-weight: 400;
        padding-left: 10px !important;
        padding-right: 10px !important;
        // padding: 10px !important;
        width: 60px !important;
        div {
          margin: 0 auto;

          p {
            // padding-right: 5px;
            // padding-left: 5px;
            text-align: center;
          }
        }
      }

      td:nth-child(2) {
         width: 150px;
        font-weight: 400;
        padding: 0 !important;


        div {
          p {
            padding-right: 10px;
            text-align: end;
          }
        }

        @media(min-width: 1200px) {
          width: 30%;
          padding: 0px !important;
        }
      }

      td:nth-child(3) {
        width: 100px !important;
        font-weight: 400  !important;
        div {
          // width: 90px;
          padding: 0 10px;

          @media(min-width: 1200px) {
            padding: auto;
            width: 100px !important;

          }
        }

        @media(min-width: 1200px) {
          width: 30%;
        }
      }

      td:nth-child(4) {
        // width: 30%;
        font-weight: 400;
        padding-right: 10px !important;

        width: max-content;

        div {
          padding-right: 5px;

          @media(min-width: 1200px) {
            padding: auto;
          }
        }

        @media(min-width: 1200px) {
          width: max-content
        }
      }

      td {
        padding: 0 !important;

        div {
          p {
            font-size: 14px;

            @media(min-width: 1200px) {
              font-size: 16px;
            }
          }
        }
      }
    }

}
    `

    const [videoVisible, setVideoVisible] = useState(true)
    const [timerVisible, setTimerVisible] = useState(false)
    const [bidsVol, setBidsVol] = useState<any>()
    const [isCollapsed, setIsCollapsed] = useState(false)

    useEffect(() => {
        // Скрываем GIF через 2.88 секунды
        const timer = setTimeout(() => {
            setVideoVisible(false);
        }, 3000); // 2.88 секунды = 2880 миллисекунд

        setTimerVisible(true)

        // Очищаем таймер при размонтировании компонента
        return () => clearTimeout(timer);
    }, []);

    const isDesktop = useMediaQuery({
        query: "(min-width: 1200px)",
    });


    useEffect(() => {
        battleAPI.getBattleById(battleId)
            .then(data => {
                setBattle(data)
                setBidsVol(data?.bids_amount)
                if (data.state === 'completed') {
                    if (data.current_tournament_stage === 3) {
                        toast.info('The tournament is over. Please choose another one.')

                    } else {

                        toast.info('The battle is over. Please choose another one.')
                    }
                } else if (data.state === 'running') {
                    toast.info("Listen to the songs, choose the top one and cast your vote!")
                }
            })
            .catch(() => {
                toast.error("Login or Register to see battle")
            })


    }, [])

    const getName = (track_id) => {
        if (track_id === battle?.track1?.id) {
            return battle?.track1?.artists_names[0]?.name
        } else {
            return battle?.track2?.artists_names[0]?.name
        }
    }


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <span>{text}</span>

            ),
        },
        {
            title: 'Vote',
            dataIndex: 'vote',
            key: 'vote',
            render: (text, record) => (
                <span>{getName(record.track_id)}</span>

            ),
        },
        {
            title: 'Amount',
            dataIndex: 'vote',
            key: 'vote',
            render: (text, record) => (
                <span>{parseInt(record.amount)} VST</span>

            ),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => (
                <span>{momentTimeZone(record.updated_at, 'MMM D, YYYY, HH:mm')}</span>

            ),
        },
    ]


    useEffect(() => {

    }, [bidsVol])

    const refetch = () => {
        battleAPI.getBattleById(battleId)
            .then(data => {
                setBattle(data)
                setBidsVol(data?.bids_amount);
            })
            .catch(() => {
                toast.error("Login or Register to see battle");
            });
    };
    if (battle) {
        if (isDesktop) {
            return (
                <>
                    <Row justify={'center'}>
                        <Col>
                            <ShowMore>
                                <p className={s.info} style={{ margin: 5 }}> <span>✅</span>  Cast your votes in the battle <span>⚔️⚡️</span> (Vote Amount ) <br />
                                    <span>✅</span>  After the battle between the 2 songs ends ⏰,
                                    an automatic vote calculation  will occur to determine the track with the most votes ⚖️ as the winner <br />
                                    <span>✅</span> Votes casted for the losing track are distributed among participants who voted for the winning track , proportionally to the amount of tokens they placed
                                </p>
                            </ShowMore>

                            <GetTokens />
                        </Col>
                    </Row>
                    {/* <MusicHeader shareable title={`Battle #${display}`} returnable primaryColor='#fff' /> */}
                    <p className={s.bidsAmount}>Total Votes {bidsVol} VST</p>

                    <div className={s.timer_wrapper}>
                        <Timer end={battle?.ended_at} start={battle?.started_at} />
                    </div>

                    <Row justify={'center'} style={{ flexDirection: 'row' }} align={'top'}>
                        <Col md={11}>
                            <Reveal triggerOnce delay={100} keyframes={customAnimation}>
                                <MusicBattleItem
                                    artist={battle?.track1.artists_names}
                                    id={battle.id}
                                    track={battle?.track1}
                                    song={battle?.track1.name}
                                    primaryColor={battle?.category?.data?.primary_color}
                                    secondaryColor={battle?.category?.data?.secondary_color}
                                    video_240_url={battle?.track1?.video_240_url}
                                    video_360_url={battle?.track1?.video_360_url}
                                    video_480_url={battle?.track1?.video_480_url}
                                    video_720_url={battle?.track1?.video_720_url}
                                    video_1080_url={battle?.track1?.video_1080_url}
                                    track_id={battle?.track1?.id}
                                    image={battle?.track1.cover}
                                    data={battle}
                                    end_date={battle.ended_at}
                                    onChange={() => refetch()}
                                />
                            </Reveal>
                        </Col>
                        <Col md={11}>
                            <Reveal triggerOnce delay={100} keyframes={customAnimation}>
                                <MusicBattleItem
                                    artist={battle?.track2.artists_names}
                                    song={battle?.track2.name}
                                    id={battle.id}
                                    track={battle?.track2}
                                    primaryColor={battle?.category?.data?.primary_color}
                                    secondaryColor={battle?.category?.data?.secondary_color}
                                    video_240_url={battle?.track2?.video_240_url}
                                    video_360_url={battle?.track2?.video_360_url}
                                    video_480_url={battle?.track2?.video_480_url}
                                    video_720_url={battle?.track2?.video_720_url}
                                    video_1080_url={battle?.track2?.video_1080_url}
                                    track_id={battle?.track2?.id}
                                    image={battle?.track2.cover}
                                    data={battle}

                                    end_date={battle.ended_at}
                                    onChange={() => refetch()}
                                />
                            </Reveal>
                        </Col>
                        <Col span={19}>
                            <StyledTable columns={columns} pagination={{ pageSize: 3 }} dataSource={battle?.current_user_bids} />
                        </Col>
                        <Col span={19}>
                            <div className={s.comments_wrapper}>
                                <Comments videoId={battleId} />
                            </div>
                        </Col>
                    </Row>
                </>
            )
        }

        else {
            return (
                <>
                    {!isDesktop && <MusicHeader shareable title={`Battle ${display}`} returnable primaryColor='#fff' />}
                    <Row justify={'center'}>
                        <Col span={23}>
                            <ShowMore>
                                <p className={s.info} style={{ margin: 5 }}> <span>✅</span>  Cast your votes in the battle <span>⚔️⚡️</span> (Vote Amount ) <br />
                                    <span>✅</span>  After the battle between the 2 songs ends ⏰,
                                    an automatic vote calculation  will occur to determine the track with the most votes <span>⚖️</span> as the winner <br />
                                    <span>✅</span> Votes casted for the losing track are distributed among participants who voted for the winning track , proportionally to the amount of tokens they placed
                                </p>
                            </ShowMore>

                            <GetTokens />
                        </Col>
                    </Row>
                    <p className={s.bidsAmount}> Total Votes {bidsVol} VST</p>
                    <Row justify={'center'}>
                        <Col span={24}>
                            <Reveal triggerOnce delay={100} keyframes={customAnimation}>
                                <Slide triggerOnce delay={110} direction={'down'}>

                                    <MusicBattleItem
                                        artist={battle?.track1.artists_names}
                                        id={battle.id}
                                        track={battle?.track1}
                                        song={battle?.track1.name}
                                        primaryColor={battle?.category?.data?.primary_color}
                                        secondaryColor={battle?.category?.data?.secondary_color}
                                        video_240_url={battle?.track1?.video_240_url}
                                        video_360_url={battle?.track1?.video_360_url}
                                        video_480_url={battle?.track1?.video_480_url}
                                        video_720_url={battle?.track1?.video_720_url}
                                        video_1080_url={battle?.track1?.video_1080_url}
                                        track_id={battle?.track1?.id}
                                        image={battle?.track1.cover}
                                        data={battle}

                                        end_date={battle.ended_at}
                                        onChange={() => refetch()}

                                    />
                                </Slide>
                            </Reveal>
                        </Col>
                    </Row>
                    <Reveal triggerOnce delay={100} keyframes={customAnimation}>
                        {/* <Row style={{ height: "120px" }} align={'middle'} justify={'center'}> */}
                        <Row align={'middle'} justify={'center'}>
                            <Reveal triggerOnce delay={3100} style={{ width: '100%' }} keyframes={customAnimationHeight}>
                                <Col span={24} style={{ position: 'relative' }}>
                                    {/* <video ref={videoRef} onEnded={() => { setVideoVisible(false); setTimerVisible(true) }} style={!videoVisible ? { display: 'none' } : { display: 'block', margin: "0 auto" }} src={vs} muted  playsInline/> */}
                                    <img style={!videoVisible ? { display: 'none' } : { display: 'block', margin: "0 auto" }} src={gif} />
                                    {/* <img style={ { display: 'block', margin: "0 auto", position: 'absolute' }} src={gif} /> */}
                                    <Fade triggerOnce delay={4000}>

                                        <div style={!timerVisible ? { display: 'none' } : {}} className={s.timer_wrapper}>
                                            {/* <div style={{}} className={s.timer_wrapper}> */}
                                            <Timer end={battle?.ended_at} start={battle?.started_at} />

                                        </div>

                                    </Fade>
                                </Col>
                            </Reveal>
                        </Row>
                    </Reveal>
                    <Row>

                        <Col span={24}>
                            <Reveal triggerOnce delay={100} keyframes={customAnimation}>
                                <Slide triggerOnce direction='up' delay={110}>
                                    <MusicBattleItem
                                        artist={battle?.track2.artists_names}
                                        song={battle?.track2.name}
                                        id={battle.id}
                                        track={battle?.track2}
                                        primaryColor={battle?.category?.data?.primary_color}
                                        secondaryColor={battle?.category?.data?.secondary_color}
                                        video_240_url={battle?.track2?.video_240_url}
                                        video_360_url={battle?.track2?.video_360_url}
                                        video_480_url={battle?.track2?.video_480_url}
                                        video_720_url={battle?.track2?.video_720_url}
                                        video_1080_url={battle?.track2?.video_1080_url}
                                        track_id={battle?.track2?.id}
                                        image={battle?.track2.cover}
                                        data={battle}

                                        onChange={() => refetch()}
                                        end_date={battle.ended_at} />
                                </Slide>
                            </Reveal>
                        </Col>
                    </Row>
                    <Fade style={{ width: '100%', margin: '0 auto', display: 'block' }} triggerOnce delay={200}>
                        <Row justify={'center'}>
                            <Col span={23}>
                                <StyledTable className={s.table} columns={columns} pagination={{ pageSize: 3 }} dataSource={battle?.current_user_bids} />
                            </Col>
                            <Col span={24}>
                                <div className={s.comments_wrapper}>
                                    <Comments videoId={battleId} />
                                </div>
                            </Col>
                        </Row>
                    </Fade>

                </>
            )
        }
    }


    return <></>
};



export { BattleItem };
