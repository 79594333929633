import React from "react";
import styles from './MarketplaceSong.module.scss'
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { sprintf } from 'sprintf-js'

interface SongProps {
 cover: string,
 id: number,
 genre: any,
 artist: any,
 track: string,
 year: number,
 price: number
}

const MarketplaceSong = (props: SongProps) => {



 const navigate = useNavigate()

 return (
  <div className={styles.wrapper}>
   <div className={styles.wrapper_info}>

    <div className={styles.cover}>
     <img className={styles.cover_image} src={props.cover} alt="cover" />
    </div>
    <div className={styles.info_block}>
     <p>Genre: <span translate="no">{props.genre.map((genre, index) =>
        (index === props.genre.length - 1 ? genre.name : `${genre.name}, `)
      )}</span></p>
     <p>Artist: <span translate="no">{props.artist.map((artist, index) =>
        (index === props.artist.length - 1 ? artist.name : `${artist.name}, `)
      )}</span></p>
     <p>Track: <span translate="no">{props.track}</span></p>
     <p>Year: {props.year}</p>
     <p>Value: {sprintf('%.2f',props.price)} VST</p>
    </div>
   </div>
  </div>
 )
}

export default MarketplaceSong;
