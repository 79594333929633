import React from "react";
import styles from './BattleDivider.module.scss'
import thunder from '../../assets/musicbet/bolt.svg'
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import { ReactComponent as Tba } from '../../assets/tba_battle.svg'

import { ReactComponent as TitleDesktop } from '../../assets/musicbet/cards/desktop-menu.svg'
import { ReactComponent as SubtitleDesktop } from '../../assets/musicbet/cards/desktop-menu-sub.svg'

interface DividerProps {
  isDayX?: boolean
  primaryColor?: string,
  secondaryColor?: string
  id?: any
  display_id?: any
  end_date?: string
  img?: string
  idk: string
  state?: string
  data?: any
}

const BattleDivider = (props: DividerProps) => {

  function getWinnerName() {
    const winnerTrackId = props?.data?.data?.winner_track_id;
    let winnerName = '';

    if (props?.data?.track1.id === winnerTrackId) {
      winnerName = props?.data?.track1.artists_names.map(artist => artist.name).join(' & ');
    } else if (props.data.track2.id === winnerTrackId) {
      winnerName = props.data?.track2.artists_names.map(artist => artist.name).join(' & ');
    }

    return winnerName || 'Unknown winner';
  }

  const location = useLocation()
  const navigate = useNavigate()

  return (
    <div onClick={localStorage.getItem('isAuth') ? location.pathname !== "/tournament/" ? () => { } : props.id === undefined ? () => { } : () => navigate(`/battle/?id=${props.id}&display=${props.display_id}`) : () => { }} className={styles.wrapper}>
      <div className={styles.main_wrapper}>
        {
          props?.state === 'completed' && <p className={styles.winner_info}>Winner: <span translate="no">{getWinnerName()}</span></p>
        }
        {
          props.data?.state === "completed" ?
            <img style={(props.data?.state === "completed" && location.pathname === "/tournament/") ? { filter: 'grayscale(100%)' } : {}} className={styles.cover} src={props.data.data.static === undefined ? props.img : props.data.data.static} alt="battle" />
            :
            <img style={(props.data?.state === "completed" && location.pathname === "/tournament/") ? { filter: 'grayscale(100%)' } : {}} className={styles.cover} src={props.img === undefined ? props.idk : props.img} alt="battle" />
        }
      </div>
    </div>
  )
}

export default BattleDivider;
