import React, { useEffect, useState } from "react";

import styled from './MyMusic.module.scss'
import trophy from '../../assets/musicbet/trophy.svg'
import income from '../../assets/musicbet/work_update.svg'
import { Button, Input, Modal, Popover } from "antd";
import { CloseOutlined, CopyFilled } from "@ant-design/icons";
import useClipboard from "react-use-clipboard";
import { autctionApi, tracksAPI } from "../../api/api";
import moment from "moment";
import { toast } from "react-toastify";
import { ReactComponent as GiftOutlined } from '../../assets/svg/featured_seasonal_and_gifts_24dp_5F6368_FILL0_wght500_GRAD0_opsz24.svg'
import { sprintf } from 'sprintf-js'
import styles from '../../pages/Auction/Auction.module.scss'
import { ReactComponent as Bid } from '../../assets/svg/profile-main-btn-svg/auction.svg'
import { useNavigate } from "react-router-dom";

interface MyMusicProps {
  image: string,
  genre: string,
  artist: string,
  track: string,
  year: number,
  price: number,
  win: number,
  income: number
  code: string,
  id: any,
  data: any
  onBuyBack?: any
}

const MyMusic = (props: MyMusicProps) => {

  const [codeOpen, setCodeOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState('');
  const [selectedValue, setSelectedValue] = useState<any>(null)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [startPrice, setStartPrice] = useState(null)

  const [isCopied, setCopied] = useClipboard(props.code);
  const handleModalCancel = () => {
    setSelectedValue(null)
    setCreateModalOpen(false)

  }
  const navigate = useNavigate()
  const startAuction = () => {
    autctionApi
      .createAuction(props.id, startPrice)
      .then(() => {
        toast.success('Auction Created')
      })
      .then(() => {
        setCreateModalOpen(false)
      })
      .then(() => navigate('/profile/auction'))
      // .then(() => {
      //   autctionApi
      //     .getAuction(perAll, pageAll)
      //     .then((res) => {
      //       setData(res)
      //       setSelectedValue(null)
      //     })
      //     autctionApi.getParticipatedAuction(per, page)
      //     .then((res) => {
      //       setYourAuctions(res)
      //     })
      // })
      .catch(() => {
        toast.success('Something is wrong')

      })
  }

  const handleBuyBack = () => {
    tracksAPI.buyback(props?.id)
      .then((res) => {
        toast.success('You buybacked track')
      })
      .then(() => {
        props.onBuyBack()
      })
      .catch((res) => {
        toast.error('Something is wrong')
      })
  }

  useEffect(() => {
    const updateTimer = () => {
      const now = moment();
      const targetDate = moment(props.data?.may_buyback_at);

      if (targetDate.isAfter(now)) {
        const duration = moment.duration(targetDate.diff(now));
        const months = Math.floor(duration.asMonths());
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        setTimeLeft(
          `${months > 0 ? `${months}m ` : ''}${days}d ${hours}h ${minutes}m ${seconds}s`
        );
      } else {
        setTimeLeft('Expired');
      }
    };

    updateTimer(); // Update immediately on mount
    const intervalId = setInterval(updateTimer, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [props.data?.may_buyback_at]);

  const timer = () => {

    if (props.data?.may_buyback_at) {
      const mayBuybackAt = new Date(props.data.may_buyback_at);
      const now = new Date();

      if (mayBuybackAt > now) {
        // Ваше действие, если may_buyback_at больше текущего времени
        return (
          <Popover content={
            <div className={styled.buyback_amount}>
              <p className={styled.buyback_text}>You can sell back in:</p> <br />
              <p className={styled.buyback_text}>      {timeLeft}     </p>
            </div>
          }>
            <Button className={styled.gift} style={{ marginTop: 10 }}>Sell back</Button>
          </Popover>

        )
      } else {
        return (
          <Popover content={
            <div className={styled.buyback_amount}>
              <p className={styled.buyback_text}>Buyback amount: {props?.data?.buyback_amount} VST</p>
              <Button className={styled.gift} onClick={handleBuyBack} style={{ marginTop: 10 }}>Sell back</Button>
            </div>
          }>

            <Button className={styled.gift} style={{ marginTop: 10 }}>Sell back</Button>
          </Popover>

        )
      }
    }

  }

  return (
    <div className={styled.wrapper}>
      <div className={styled.info_wrapp}>
        <div className={styled.image_wrapper}>
          <img src={props.image} className={styled.image} alt="cover" />
        </div>
        <div className={styled.info_content}>
          <p>Genre: <span translate="no">{props.genre}</span></p>
          <p>Artist: <span translate="no">{props.artist}</span></p>
          <p>Track: <span translate="no">{props.track}</span></p>
          <p>Year: {props.year}</p>
          <p>Price:  {sprintf('%.2f', props.price)}</p>
          <div className={styled.buttons_wrapper}>
            <Button className={styled.gift} onClick={() => setCodeOpen(true)} style={{ marginTop: 10 }}><GiftOutlined style={{ width: 20 }} /> Gift to</Button>
            {timer()}
            <Button className={styled.gift} onClick={() => setCreateModalOpen(true)} style={{ marginTop: 10 }}>Auction Off</Button>

          </div>
        </div>
      </div>
      <div className={styled.additional}>
        <div>
          <p className={styled.additional_p}>Track Wins / VST</p>
        </div>
        <div className={styled.trohpeys}>
          <div className={styled.trophy}>
            <img src={trophy} alt="tophy" />
            <p>{props.win}</p>
          </div>
          <div className={styled.trophy}>
            <img src={income} alt="income" />
            <p>{sprintf('%.2f', props.income)}</p>
          </div>
          <div>
          </div>
        </div>
      </div>
      <Modal footer={null} closeIcon={<CloseOutlined style={{ fill: '#fff', color: "#fff" }} color="#fff" />} open={codeOpen} onCancel={() => setCodeOpen(false)}>
        <div className={styled.code_modal}>

          <h2 className={styled.header_modal}>Copy the code to gift a card</h2>
          <div className={styled.input_field}>
            <Input disabled defaultValue={props.code} />
            <Popover
              content={
                <p className={styled.popover}>Gift card codes can be sent to the recipient by chosen method of the member. <br />
                  The recipient can activate the card after registration on the platform by entering the code in the My Collection section.</p>
              }
            >
              <Button onClick={setCopied} className={styled.button_copy}><CopyFilled /> {isCopied ? 'Copied!' : 'Copy'} </Button>
            </Popover>
          </div>
        </div>
      </Modal>

      <Modal open={createModalOpen}
        footer={null}
        destroyOnClose
        onCancel={handleModalCancel}
        closable>
        <div className={styles.modal}>
          <div className={styles.modal_header}>
            <h2>Put Up For Auction</h2>
            <CloseOutlined style={{ color: '#fff', fill: '#fff' }} onClick={() => setCreateModalOpen(false)} />
          </div>
          <div className={styles.body}>
          </div>


              <div className={styles.additional_info}>
                <img className={styles.inner_image} src={props.image} alt="cover" />
                <p className={styles.text_info}>Track: <span translate="no">{props.track}</span></p>
                <p className={styles.text_info}>Artist: <span translate="no">{props.artist}</span></p>
                <p className={styles.text_info}>Genre: <span>{props.genre}</span></p>
                <p className={styles.text_info}>Price: {sprintf('%.2f', props.price)} VST</p>
                <p className={styles.text_info}>Auction Duration: {moment().format('DD MMMM YYYY')} - {moment().add(7, 'days').format('DD MMMM YYYY')}</p>
                <Input onChange={(e) => { setStartPrice(parseInt(e.target.value)) }} className={styles.price_input} type="number" placeholder="Starting Price" />
                <Button onClick={() => { startAuction() }} className={styles.start_button}><Bid className={styles.auction_icon} />Start Auction</Button>
              </div>


        </div>

      </Modal>
    </div>
  )
}

export default MyMusic;
